import axios from 'axios';
import owlCarousel from 'owl.carousel';
$(document).ready(function(){
    /* owl carousel initiation */
    $('.slider').owlCarousel({
        items:1,
        autoplay:false,
        autoplayTimeout:5000,
        loop:false,
        dots:false,
        nav:false,
        margin:0,
        responsive:{
            575:{
                dots:true
            }
        }
    });
    $('.product-slider').owlCarousel({
        items:1,
        autoplay:false,
        autoplayTimeout:6000,
        loop:true,
        dots:false,
        navText: ["<ion-icon name='chevron-back-outline'></ion-icon>","<ion-icon name='chevron-forward-outline'></ion-icon>"],
        nav:true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        margin:0,
        responsive:{
            768:{
                items:2,
                margin:32,
            },
            992:{
                items:3,
                margin:32,
            },
            1200:{
                items:4,
                margin:32,
            }
        }
    });
    $('.client-slider').owlCarousel({
        items:1,
        autoplay:false,
        autoplayTimeout:6000,
        loop:true,
        dots:true,
        nav:false,
        navText: ["<ion-icon name='chevron-back-outline'></ion-icon>","<ion-icon name='chevron-forward-outline'></ion-icon>"],
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        margin:0,
        responsive:{
            768:{
                items:2,
                margin:32,
            },
            992:{
                items:3,
                margin:32,
                dots:false,
                nav:true
            },
            1200:{
                items:4,
                margin:32,
                dots:false,
                nav:true
            }
        }
    });
    $('.testimonial-slider').owlCarousel({
        items:1,
        autoplay:false,
        autoplayTimeout:6000,
        loop:true,
        dots:true,
        nav:false,
        navText: ["<ion-icon name='chevron-back-outline'></ion-icon>","<ion-icon name='chevron-forward-outline'></ion-icon>"],
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        margin:32,
        responsive:{
            992:{
                items:2,
                dots:false,
                nav:true
            },
            1200:{
                items:3,
                dots:false,
                nav:true
            },
        }
    });
    $('.branches-slider').owlCarousel({
        items:1,
        autoplay:false,
        autoplayTimeout:6000,
        loop:false,
        dots:false,
        nav:false,
        animateIn: 'fadeIn',
        margin:32,
        responsive:{
            992:{
                items:2,
            },
            1200:{
                items:3,
            }
        }
    });
    $(".datepicker").datepicker(); 
})