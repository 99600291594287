import {isMobile,isTablet} from 'mobile-device-detect';
$(document).ready(function(){
    /* add background images */
    (function imageToBackground(){
        let section = $(".image-as-bg");
        section.each(function(){
            let imageSource = $(this).attr("data-bg");
            let browserHeight = $(this).attr("data-browser-height") ? $(this).attr("data-browser-height") : `auto`;
            let mobileHeight = $(this).attr("data-mobile-height") ? $(this).attr("data-mobile-height") : browserHeight;
            let tabletHeight = $(this).attr("data-tablet-height") ? $(this).attr("data-tablet-height") : browserHeight;
            let height;
            if(isMobile){
                height=mobileHeight;
            }else if(isTablet){
                height=tabletHeight;
            }else{
                height=browserHeight
            }
            let bg = $(this).attr("data-styles") ? $(this).attr("data-styles") : "no-repeat center / cover";
            $(this).css({
                "background":`url(${imageSource}) ${bg}`,
                "height" : (height !== `auto`) ? `${height}px` : height 
            });
        });
    })();
    /* counter */
    let counterStart = false;
    function pageCounter(){
        let counterSec = $(".project-counter");
        if(counterStart === false){
            counterSec.find(".count-dat").each(function(){
                let counter = $(this),
                cData = counter.attr("data-number"), 
                cSymbol = counter.attr("data-symbol") ? counter.attr("data-symbol") : "",
                i = 0,
                counterStart = function() {
                    counter.html(++i + cSymbol);
                    if(i != cData) {
                        setTimeout(counterStart,20);
                    }
                };
                counterStart();
            })
        }
        counterStart = true;
    };
    (function passwordToText(){
        let passGroup = $(".password-to-text");
        passGroup.each(function(){
            let passPhrase = $(this).find('input[type="password"]');
            let passButton = $(this).find(".pass-toggler");
            passButton.click(function(){
                $(this).toggleClass("show-password");
                if($(this).hasClass("show-password")){
                    passPhrase.attr("type","text");
                }else{
                    passPhrase.attr("type","password");
                }
            })
        })
    })();
    /* responsive paragraph collapsible accordion */
    (function mobileResponsiveParagraphCollapse() {
        let parent = $(".paragraph-collapsible");
        let element = parent.find("p:nth-child(odd)");
        parent.find("p:nth-child(even)").not($(".shown").next()).hide();
        element.addClass("interact");
        element.click(function() {
            parent.find("p:nth-child(odd)").not($(this)).removeClass("shown");
            parent.find("p:nth-child(even)").not($(this).next()).slideUp();
            $(this).toggleClass("shown");
            $(this).next().slideToggle();
        });
    })();
    function fixNavigation(){
        let menus = $("#menu-lists");
        let logoSec = $("#logo-sec");
        let navbar = $("#navbarSupportedContent .navbar-nav");
        let demoLink = $("#demo-link");
        let navOut = $("._navlist");
        logoSec.html($(".navbar").find(".navbar-brand"));
        menus.find("li").each(function(){
            let item = $(this);
            item.removeClass("list-inline-item").addClass("nav-item");
            item.find("a").addClass("nav-link");
        })
        navOut.each(function(){
            console.log(navOut);
            $(".navbar").find(".btn-wrap").prepend($(this).clone().html());
        })
        navOut.remove();
        menus.parent().remove("justify-content-end").html(demoLink);
        navbar.prepend(menus.html())
        $("footer").css("marginBottom",66)
        console.log($(".header-mid").height());
        $(".site-header").css({
            "opacity":1,
            "pointer-events":"initial"
        });
    }
    if($(window).width() < 992){
        fixNavigation();
    }
    (function templateInteractivity(){
        let template = $(".template-data");
        template.find("*").addClass("interact")
    })();
    (function browse_image(){
        let imageGroup = $(".image-group")
        imageGroup.each(function(){
            let element = $(this);
            let inputElm = element.find(`input[type="file"`);
            let bg = element.find(".preview-image");
            let tempPath = "";
            inputElm.change(function(e){
                    tempPath = URL.createObjectURL(e.target.files[0]);
                    bg.attr('style',"background:url(" + tempPath + ') center center / cover');     
            });
        })
    })();
    (function removeCookieOnScroll(){
        let cookieSection = $(".cookies-section");
        setTimeout(() => {
            cookieSection.find(".close").click();
        },10000)
    })();
    $(window).scroll(function(){
        let window = $(this);
        let scrollPos = window.scrollTop();
        (function interact(){
            let section = $(".interact");
            section.each(function(){
                let elementTop = $(this).offset().top,
                elementBottom = elementTop + $(this).outerHeight(),
                viewportBottom = scrollPos + window.innerHeight();
                console.log($(this).attr("id"));
                if((viewportBottom > elementTop) && (scrollPos < elementBottom)){
                    $(this).addClass("activate-interactivity");
                    if($(this).attr("id") == "counter-banner"){
                        pageCounter();
                    }
                }
            });
        })();
        (function stickNavigation(){
            let header = $(".site-header");
            if(scrollPos > 32){
                header.addClass("sticky-header");
            }else{
                header.removeClass("sticky-header");
            }
        })();
    });
    $(window).scroll();
})