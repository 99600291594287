/* css files */
import "bootstrap/scss/bootstrap.scss"
import "owl.carousel/src/scss/owl.carousel.scss"
import "jquery-ui-dist/jquery-ui.css"
import "smartmenus-bootstrap-4/jquery.smartmenus.bootstrap-4.css"
import "./public/assets/sass/main.scss"
/* js files */
import 'popper.js/dist/popper'
import "bootstrap/dist/js/bootstrap"
import "jquery-ui-dist/jquery-ui"
import "owl.carousel/src/js/owl.carousel"
import "smartmenus/dist/jquery.smartmenus"
import "smartmenus-bootstrap-4/jquery.smartmenus.bootstrap-4"
import "./public/assets/js/initiation"
import "./public/assets/js/custom"

